import Bouncer from 'formbouncerjs';
import {SortableList} from './oscar-sortable-lists';
import EditableTable from './oscar-editable-tables';
import MatrixField from './oscar-matrix';
import atomic from 'atomicjs/dist/atomic.min.js';
import StatusMessage from './oscar-status-messages';
import AriaAutocomplete from 'aria-autocomplete';
import {Datepicker} from 'vanillajs-datepicker';

;(function() {

    'use strict';

    /******************************************
     * Date Fields
     *
     * Add Datepicker to date fields
     ******************************************/
    const dateFields = document.querySelectorAll('input[data-date-field]');

    let dateFieldsCollection = {};

    const initDateFields = function(fields, context) {
        Array.prototype.forEach.call(fields, function(field) {
            // Delete any existing date fields in context
            if (dateFieldsCollection[`${context}-${field.id}`] !== undefined) {
                dateFieldsCollection[`${context}-${field.id}`].destroy();
            }
            const dateOpts = field.dataset.dateField ? JSON.parse(field.dataset.dateField) : {};
            dateFieldsCollection[`${context}-${field.id}`] = new Datepicker(field, dateOpts);
        });
    };

    document.addEventListener('oscar.initDateFields', function(ev) {
        let fields = ev.target.closest('form').querySelectorAll('[data-date-field]');
        let context = ev.target.closest('form').id;

        if (fields.length) {
            initDateFields(fields, context);
        }
    });

    if (dateFields.length) {
        Array.prototype.forEach.call(dateFields, function(field) {
            let context = field.closest('form').id;
            const dateOpts = field.dataset.dateField ? JSON.parse(field.dataset.dateField) : {};
            dateFieldsCollection[`${context}-${field.id}`] = new Datepicker(field, dateOpts);
        });
    }

    /******************************************
     * Password Fields
     *
     * Add Show/Hide button to password fields
     ******************************************/
    const passwordFields = document.querySelectorAll('input[data-password-field]');

    if (passwordFields) {
        const togglePasswordField = function(field) {
            field.type = field.type === 'password' ? 'text' : 'password';
        };

        const togglePasswordFieldButtonText = function(button) {
            button.innerText = button.innerText === 'Show' ? 'Hide' : 'Show';
        };

        document.addEventListener('click', function(ev) {
            if (ev.target.matches('input[data-password-field] + button')) {
                togglePasswordFieldButtonText(ev.target);
                togglePasswordField(ev.target.parentNode.querySelector('input[data-password-field]'));
            }
        });
    }

    /******************************************
     * Select Fields with Other option
     *
     * Toggle other field visibility for
     * select fields with other option
     ******************************************/
    const otherSelectFields = document.querySelectorAll('select[data-other-trigger]');

    if (otherSelectFields) {
        document.addEventListener('change', function(ev) {
            if (ev.target.matches('select[data-other-trigger]')) {
                let selectVal = ev.target.value;
                let otherTrigger = ev.target.dataset.otherTrigger;
                const otherInputs = ev.target.parentNode.querySelectorAll('[data-other]');

                if (selectVal.toLowerCase().includes(otherTrigger)) {
                    Array.prototype.forEach.call(otherInputs, function(input) {
                        input.classList.remove('hidden');
                    });
                } else {
                    Array.prototype.forEach.call(otherInputs, function(input) {
                        input.classList.add('hidden');
                    });
                }
            }
        });
    }

    /******************************************
     * Entries Select Fields
     *
     * Use Aria Autocomplete to choose
     * entries from one section
     ******************************************/
    let entriesFields = document.querySelectorAll('[data-entries-select]');

    let entriesFieldCollection = {};

    const initEntriesSelectFields = function(fields, context) {
        Array.prototype.forEach.call(fields, function(field) {
            // Delete any existing entries select fields in context
            if (entriesFieldCollection[`${context}-${field.id}`] !== undefined) {
                entriesFieldCollection[`${context}-${field.id}`].destroy();
            }
            field.multiple = true;
            entriesFieldCollection[`${context}-${field.id}`] = AriaAutocomplete(field, {
                placeholder: 'Type to select',
                deleteOnBackspace: true,
                showAllControl: true,
                autoGrow: true,
                create: false,
                minLength: 0,
                maxResults: 9999,
                maxItems: 9999,
                multiple: true,
                listClassName: 'list-none m-0 p-0 leading-loose',
            });
        });
    };

    document.addEventListener('oscar.updateEntriesSelectFields', function(ev) {
        let fields = ev.target.closest('form').querySelectorAll('[data-entries-select]');
        let context = ev.target.closest('form').id;

        if (fields.length) {
            initEntriesSelectFields(fields, context);
        }
    });

    if (entriesFields.length) {
        Array.prototype.forEach.call(entriesFields, function(field) {
            let context = field.closest('form').id;
            entriesFieldCollection[`${context}-${field.id}`] = AriaAutocomplete(field, {
                placeholder: 'Type to select',
                deleteOnBackspace: true,
                showAllControl: true,
                autoGrow: true,
                create: false,
                minLength: 0,
                maxResults: 9999,
                maxItems: 9999,
                multiple: true,
                listClassName: 'list-none m-0 p-0 leading-loose',
            });
        });

        document.addEventListener('change', function(ev) {
            if (ev.target.matches('[data-entries-select-disable-autocomplete]')) {
                let context = ev.target.closest('form').id;
                let field = ev.target.closest('div').querySelector('[data-entries-select]');

                if (context && field) {
                    if (ev.target.checked) {
                        // disable autocomplete and show checkboxes
                        entriesFieldCollection[`${context}-${field.id}`].destroy();
                    } else {
                        // enable autocomplete and hide checkboxes
                        entriesFieldCollection[`${context}-${field.id}`] = AriaAutocomplete(field, {
                            placeholder: 'Type to select',
                            deleteOnBackspace: true,
                            showAllControl: true,
                            autoGrow: true,
                            create: false,
                            minLength: 0,
                            maxResults: 9999,
                            maxItems: 9999,
                            multiple: true,
                            listClassName: 'list-none m-0 p-0 leading-loose',
                        });
                    }
                }
            }
        });
    }

    /******************************************
     * Lightswitch Fields
     *
     ******************************************/
    const lightswitches = document.querySelectorAll('[data-lightswitch]');

    if (lightswitches) {
        const toggleLightswitch = function(el) {
            let isOn = el.checked;

            const toggle = el.parentNode.querySelector('[data-toggle]');
            const on = el.parentNode.querySelector('[data-on]');
            const off = el.parentNode.querySelector('[data-off]');
            const track = el.parentNode.querySelector('[data-track]');

            if (isOn) {
                toggle.classList.add('border-green-600');
                on.classList.remove('hidden');
                off.classList.add('hidden');
                track.classList.add('justify-end', 'bg-green-600');
                track.classList.remove('justify-start');
            } else {
                toggle.classList.remove('border-green-600');
                on.classList.add('hidden');
                off.classList.remove('hidden');
                track.classList.remove('justify-end', 'bg-green-600');
                track.classList.add('justify-start');
            }
        };

        document.addEventListener('change', function(ev) {
            if (ev.target.matches('[data-lightswitch] input[type="checkbox"]')) {
                toggleLightswitch(ev.target);
            }
        });
    }

    /******************************************
     * Auto expanding Textareas
     *
     ******************************************/
    const autoExpand = function(field) {

        // Reset field height
        field.style.height = 'inherit';

        // Get the computed styles for the element
        let computed = window.getComputedStyle(field);

        // Calculate the height
        let height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            // + parseInt(computed.getPropertyValue('padding-top'), 10)
            + field.scrollHeight
            // + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        field.style.height = height + 'px';

        // initialise editable tables for the entry
        field.dispatchEvent(
            new Event('oscar.autoExpanded', {
                "bubbles": true,
                "cancelable": true
            })
        );
    };

    document.addEventListener('input', function(event) {
        if (!event.target.matches('textarea[data-auto-expand]')) {
            return;
        }
        autoExpand(event.target);
    }, false);

    // initialise auto-expanding text areas
    window.addEventListener('load', function(ev) {
        let textareas = document.querySelectorAll('textarea[data-auto-expand]');
        if (textareas) {
            Array.prototype.forEach.call(textareas, function(item) {
                autoExpand(item);
            });
        }
    });

    /******************************************
     * Textareas with character or word limits
     *
     ******************************************/
    let maxlengthElements = document.querySelectorAll('textarea[data-limit]');

    const updateCount = function(ev) {
        let type = ev.target.parentNode.querySelector('[data-limit-type]').dataset.limitType;

        if (type === 'characters') {
            // For character limits, we only need to update the count as the browser will take care of the limiting natively
            ev.target.parentNode.querySelector('[data-limit-count]').textContent = ev.target.value.length.toString();
        } else if (type === 'words') {
            // Check that the word count hasn't been exceeded and prune text if it has
            let wordLimit = parseInt(ev.target.parentNode.querySelector('[data-limit-amount]').dataset.limitAmount);
            let text = ev.target.value;
            let words = text.match(/[^\s]+\s?/g);
            let wordCount = words ? words.length : 0;

            if (wordCount > wordLimit) {
                let lines = text.split(/\n|\r|\r\n/);
                let emptyLines = 0;
                lines.forEach(function(line, index) {
                    lines[index] = line.trim();
                    if (lines[index].length === 0) {
                        emptyLines++;
                    }
                });
                text = lines.join('\n');
                let trimCount = wordLimit + emptyLines;
                ev.target.value = text.split(/(?=[\s+])/, trimCount).join('');
                wordCount = ev.target.value.match(/[^\s]+\s?/g).length;
            }
            // Update the word count
            ev.target.parentNode.querySelector('[data-limit-count]').textContent = wordCount.toString();
        }

        // Check for error message on textarea and hide if limit count <= limit amount
        let limitCount = parseInt(ev.target.parentNode.querySelector('[data-limit-count]').textContent);
        let limitAmount = parseInt(ev.target.parentNode.querySelector('[data-limit-amount]').dataset.limitAmount);
        let errorElem = ev.target.parentNode.querySelector('span.form-error');
        if (errorElem && !errorElem.classList.contains('hidden') && (limitCount <= limitAmount)) {
            errorElem.classList.add('hidden');
        }
    };

    if (maxlengthElements.length) {
        Array.prototype.forEach.call(maxlengthElements, (el, i) => {
            el.parentNode.querySelector('[data-limit-type]').classList.toggle('hidden');
            el.parentNode.querySelector('[data-limit-amount]').textContent = el.parentNode.querySelector('[data-limit-amount]').dataset.limitAmount;
            el.parentNode.querySelector('[data-limit-count]').textContent = el.value.length.toString();

            el.addEventListener('input', updateCount);
            el.addEventListener('blur', updateCount);
        });
    }

    /******************************************
     * Redactor fields
     *
     ******************************************/
    const initRedactorFields = function(rootElementSelector = '') {
        $R(rootElementSelector + ' [data-redactor]', {
            buttons: ['html', 'format', 'bold', 'italic', 'lists', 'link', 'horizontalrule'],
            buttonsAdd: ['line'],
            formatting: ['p', 'blockquote', 'h2', 'h3', 'h4'],
            plugins: ['table'],
            linkNewTab: true,
            toolbarFixed: false
        });
    };

    document.addEventListener('oscar.initRedactorFields', function(ev) {
        let rootElementId = ev.target.id;
        let redactorFields = document.querySelectorAll(`#${rootElementId} [data-redactor]`)

        if (rootElementId && redactorFields.length) {
            initRedactorFields(`#${rootElementId}`);
        }
    });

    /******************************************
     * Sortable lists
     *
     ******************************************/
    let sortableLists = document.querySelectorAll('[data-sortable-list]');

    let sortableListCollection = {};

    const initSortableLists = function(lists, context) {
        Array.prototype.forEach.call(lists, function(list) {
            let options = JSON.parse(list.dataset.sortableList);
            // Delete any existing sortable lists in context
            if (sortableListCollection[`${context}-${options.id}`] !== undefined) {
                sortableListCollection[`${context}-${options.id}`].destroy();
            }
            sortableListCollection[`${context}-${options.id}`] = new SortableList(list, options);
        });
    };

    document.addEventListener('oscar.updateSortableLists', function(ev) {
        let lists = ev.target.closest('form').querySelectorAll('[data-sortable-list]');
        let context = ev.target.closest('form').id;

        if (lists.length) {
            initSortableLists(lists, context);
        }
    });

    if (sortableLists.length) {
        Array.prototype.forEach.call(sortableLists, function(list) {
            let options = JSON.parse(list.dataset.sortableList);
            let context = list.closest('form').id;
            sortableListCollection[`${context}-${options.id}`] = new SortableList(list, options);
        });
    }

    /******************************************
     * Editable tables
     *
     ******************************************/
    let editableTables = document.querySelectorAll('[data-editable-table]');

    let editableTableCollection = {};

    const initEditableTables = function(tables, context) {
        Array.prototype.forEach.call(tables, function(table) {
            // Delete any existing editable tables in context
            if (editableTableCollection[`${context}-${table.dataset.editableTable}`] !== undefined) {
                editableTableCollection[`${context}-${table.dataset.editableTable}`].destroy();
            }
            editableTableCollection[`${context}-${table.dataset.editableTable}`] = new EditableTable(table);
        });
    };

    document.addEventListener('oscar.updateEditableTables', function(ev) {
        let tables = ev.target.closest('form').querySelectorAll('[data-editable-table]');
        let context = ev.target.closest('form').id;

        if (tables.length) {
            initEditableTables(tables, context);
        }
    });

    if (editableTables.length) {
        Array.prototype.forEach.call(editableTables, function(table) {
            let context = table.closest('form').id;
            editableTableCollection[`${context}-${table.dataset.editableTable}`] = new EditableTable(table);
        });
    }

    /******************************************
     * Entries lists
     *
     ******************************************/
    const loadEntry = function(id = null, editTarget, loadUrl, trigger = null) {
        if (!editTarget || !loadUrl) {
            return false;
        }

        let editTargetModal = document.querySelector(`#${editTarget}`);
        let editTargetContent = editTargetModal.querySelector('[data-content]');
        let spinner = editTargetModal.querySelector('[data-spinner]');
        editTargetContent.innerHTML = '';

        // Find trigger for target modal
        let modalOptions = JSON.parse(editTargetModal.dataset.modal);
        let modalTrigger = document.querySelector(`${modalOptions['trigger']}`);
        modalTrigger.dispatchEvent(
            new Event('click', {
                "bubbles": true,
                "cancelable": true
            })
        );
        spinner.classList.remove('hidden');

        let url = loadUrl;
        if (id) {
            url += `/${id}`;
        }

        atomic(url, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            responseType: 'text',
        })
            .then(function(response) {
                if (response) {
                    editTargetContent.innerHTML = response.data;
                    let entryForm = editTargetContent.querySelector('form');
                    spinner.classList.add('hidden');
                    // initialise form fields for loaded entry
                    entryForm.dispatchEvent(
                        new Event('oscar.initFormFields', {
                            "bubbles": true,
                            "cancelable": true
                        })
                    );
                    if (trigger) {
                        trigger.dispatchEvent(
                            new Event('oscar.setModalTrigger', {
                                "bubbles": true,
                                "cancelable": true
                            })
                        );
                    }
                }
            })
            .catch(function(error) {
                spinner.classList.add('hidden');
                console.log(error);
                console.log(error.status); // xhr.status
                console.log(error.statusText); // xhr.statusText
            });
    };

    const deleteEntry = function(id) {
        if (!id) {
            return false;
        }

        let data = {};
        let csrfTokenName = document.querySelector('body').dataset.csrfTokenName;
        let csrfTokenValue = document.querySelector('body').dataset.csrfTokenValue;
        data[csrfTokenName] = csrfTokenValue;

        data['elementId'] = id;

        atomic('actions/elements/delete', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            },
            data: data,
            responseType: 'JSON',
        })
            .then(function(response) {
                let responseData = JSON.parse(response.data);
                if (responseData) {
                    let listItem = document.querySelector(`[data-id="${id}"]`).closest('[data-sortable]');
                    listItem.parentNode.removeChild(listItem);
                    new StatusMessage('Entry deleted', 'notice');
                }
            })
            .catch(function(error) {
                console.log(error);
                console.log(error.status); // xhr.status
                console.log(error.statusText); // xhr.statusText
                return false;
            });
    };

    const initEntriesListFields = function(rootElement) {
        rootElement.addEventListener('click', function(ev) {
            if (ev.target.closest('[data-edit-entry]')) {
                let entryIdToBeEdited = ev.target.closest('[data-edit-entry]').dataset.id;
                let editTarget = ev.target.closest('[data-edit-entry]').dataset.editEntry;
                let loadUrl = ev.target.closest('[data-url]').dataset.url;
                loadEntry(entryIdToBeEdited, editTarget, loadUrl, ev.target.closest('li'));
                ev.stopPropagation();
            }

            if (ev.target.closest('[data-add-new-entry]') || ev.target.closest('[data-add-new-entry] svg')) {
                let editTarget = ev.target.closest('[data-add-new-entry]').dataset.addNewEntry;
                let loadUrl = ev.target.closest('[data-url]').dataset.url;
                loadEntry(null, editTarget, loadUrl, ev.target);
                ev.stopPropagation();
            }

            if (ev.target.closest('[data-delete-entry]')) {
                let confirmDelete = confirm('Are you sure you want to delete this entry? (You cannot undo this action.)');

                if (confirmDelete) {
                    let entryIdToBeDeleted = ev.target.closest('[data-delete-entry]').dataset.id;
                    deleteEntry(entryIdToBeDeleted);
                }
                ev.stopPropagation();
            }
        });
    };

    initEntriesListFields(document);

    /******************************************
     * File fields
     *
     ******************************************/
    const deleteFile = function(fileDeleteButton, fileId, baseInputName) {
        let data = {};
        let csrfTokenName = document.querySelector('body').dataset.csrfTokenName;
        let csrfTokenValue = document.querySelector('body').dataset.csrfTokenValue;
        data[csrfTokenName] = csrfTokenValue;

        data['assetId'] = fileId;

        atomic('/actions/assets/delete-asset', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            },
            data: data,
            responseType: 'JSON',
        })
            .then(function(response) {
                let responseData = JSON.parse(response.data);
                if (responseData) {
                    let selectedAssetImage = fileDeleteButton.closest('p').querySelector('[data-selected-asset-image]');
                    selectedAssetImage.src = '';
                    selectedAssetImage.classList.add('hidden');
                    let selectedAssetTitle = fileDeleteButton.closest('p').querySelector('[data-selected-asset-title]');
                    selectedAssetTitle.innerText = '';
                    fileDeleteButton.classList.add('hidden');
                    let assetUpload = fileDeleteButton.closest('label').querySelector('[data-asset-upload]');
                    assetUpload.classList.remove('hidden');
                    new StatusMessage('File deleted', 'notice', {container: `[data-file-notifications="${fileId}"]`, effectClass: 'fadeout'});
                }
            })
            .catch(function(error) {
                console.log(error);
                console.log(error.status); // xhr.status
                console.log(error.statusText); // xhr.statusText
                return false;
            });
    };

    const removeFile = function(fileRemoveButton, fileId, baseInputName) {
        let existingFiles = fileRemoveButton.closest('label').querySelector('[data-existing-files]');
        let hiddenInput = fileRemoveButton.closest('label').querySelector('[data-asset-id]');
        hiddenInput.value = '';
        let selectedAssetImage = fileRemoveButton.closest('label').querySelector('[data-selected-asset-image]');
        selectedAssetImage.src = '';
        selectedAssetImage.classList.add('hidden');
        let selectedAssetTitle = fileRemoveButton.closest('label').querySelector('[data-selected-asset-title]');
        selectedAssetTitle.innerText = '';
        fileRemoveButton.classList.add('hidden');
        let assetUpload = fileRemoveButton.closest('label').querySelector('[data-asset-upload]');
        assetUpload.classList.remove('hidden');
        new StatusMessage('File removed', 'notice', {container: `[data-file-notifications="${fileId}"]`, effectClass: 'fadeout'});

        if (existingFiles) {
            existingFiles.classList.remove('hidden');
        }
    };

    const addExistingFile = function(selectedAssetButton) {
        let selectedAssetId = selectedAssetButton.dataset.id;
        let selectedAssetImgUrl = selectedAssetButton.querySelector('img').src;
        let selectedImageTitle = selectedAssetButton.querySelector('[data-asset-title]').innerText;
        // Set selected image
        let selectedAssetImage = selectedAssetButton.closest('label').querySelector('[data-selected-asset-image]');
        selectedAssetImage.src = selectedAssetImgUrl;
        selectedAssetImage.classList.remove('hidden');
        // Set selected image title
        let selectedAssetTitle = selectedAssetButton.closest('label').querySelector('[data-selected-asset-title]');
        selectedAssetTitle.innerText = selectedImageTitle;
        // Set selected image hidden input
        let hiddenInput = selectedAssetButton.closest('label').querySelector('[data-asset-id]');
        hiddenInput.value = selectedAssetId;
        // Hide existing files
        let existingFiles = selectedAssetButton.closest('label').querySelector('[data-existing-files]');
        existingFiles.classList.add('hidden');
        // Show remove file button
        let fileRemoveButton = selectedAssetButton.closest('label').querySelector('[data-remove-file]');
        fileRemoveButton.dataset.id = selectedAssetId;
        fileRemoveButton.classList.remove('hidden');
        // Hide file upload button
        let assetUpload = selectedAssetButton.closest('label').querySelector('[data-asset-upload]');
        assetUpload.classList.add('hidden');
        // Update status message container
        let statusMessageContainer = selectedAssetButton.closest('label').querySelector('[data-file-notifications]');
        statusMessageContainer.dataset.fileNotifications = selectedAssetId;
    };

    const addFileEventListeners = function(rootElement) {
        rootElement.addEventListener('click', function(ev) {
            if (ev.target.closest('[data-delete-file]')) {
                let confirmDeleteFile = confirm('Are you sure you want to delete this file? (You cannot undo this action.)');

                if (confirmDeleteFile) {
                    let fileDeleteButton = ev.target.closest('[data-delete-file]');
                    let fileId = fileDeleteButton.dataset.id;
                    let baseInputName = fileDeleteButton.closest('label[data-name]').dataset.name;

                    deleteFile(fileDeleteButton, fileId, baseInputName);
                }
                ev.stopPropagation();
                ev.preventDefault();
            }
            if (ev.target.closest('[data-remove-file]')) {
                let confirmRemoveFile = confirm('Are you sure you want to remove this file? (You cannot undo this action.)');

                if (confirmRemoveFile) {
                    let fileRemoveButton = ev.target.closest('[data-remove-file]');
                    let fileId = fileRemoveButton.dataset.id;
                    let baseInputName = fileRemoveButton.closest('label[data-name]').dataset.name;

                    removeFile(fileRemoveButton, fileId, baseInputName);
                }
                ev.stopPropagation();
                ev.preventDefault();
            }
            if (ev.target.closest('[data-existing-files] [data-id]')) {
                // Move selected asset into chosen area, set id in hidden form field to submit and hide existing files - lots to do here!
                let selectedAssetButton = ev.target.closest('[data-existing-files] [data-id]');
                addExistingFile(selectedAssetButton);
                ev.stopPropagation();
            }
        });
    };

    addFileEventListeners(document);

    /******************************************
     * Matrix fields
     *
     ******************************************/
    let matrixFields = document.querySelectorAll('[data-matrix]');

    let matrixFieldCollection = {};

    const initMatrixFields = function(matrixFields, context) {
        Array.prototype.forEach.call(matrixFields, function(matrixField) {
            // Delete any existing matrix fields in context
            if (matrixFieldCollection[`${context}-${matrixField.dataset.matrix}`] !== undefined) {
                matrixFieldCollection[`${context}-${matrixField.dataset.matrix}`].destroy();
            }
            matrixFieldCollection[`${context}-${matrixField.dataset.matrix}`] = new MatrixField(matrixField);
        });
    };

    document.addEventListener('oscar.updateMatrixFields', function(ev) {
        let matrixFields = ev.target.closest('form').querySelectorAll('[data-matrix]');
        let context = ev.target.closest('form').id;

        if (matrixFields.length) {
            initMatrixFields(matrixFields, context);
            initRedactorFields(context.id);
            // initialise sortable lists
            ev.target.closest('form').dispatchEvent(
                new Event('oscar.updateSortableLists', {
                    "bubbles": true,
                    "cancelable": true
                })
            );
            // initialise editable tables for the entry
            ev.target.closest('form').dispatchEvent(
                new Event('oscar.updateEditableTables', {
                    "bubbles": true,
                    "cancelable": true
                })
            );
        }
    });

    if (matrixFields.length) {
        Array.prototype.forEach.call(matrixFields, function(matrixField) {
            let context = matrixField.closest('form').id;
            matrixFieldCollection[`${context}-${matrixField.dataset.matrix}`] = new MatrixField(matrixField);
        });
    }

    /******************************************
     * Initialise all form fields
     *
     ******************************************/
    const initFormFields = function(form) {
        // initialise date fields for the entry
        form.dispatchEvent(
            new Event('oscar.initDateFields', {
                "bubbles": true,
                "cancelable": true
            })
        );
        // initialise redactor fields for the entry
        form.dispatchEvent(
            new Event('oscar.initRedactorFields', {
                "bubbles": true,
                "cancelable": true
            })
        );
        // initialise sortable lists for the entry
        form.dispatchEvent(
            new Event('oscar.updateSortableLists', {
                "bubbles": true,
                "cancelable": true
            })
        );
        // initialise editable tables for the entry
        form.dispatchEvent(
            new Event('oscar.updateEditableTables', {
                "bubbles": true,
                "cancelable": true
            })
        );
        // initialise matrix fields for the entry
        form.dispatchEvent(
            new Event('oscar.updateMatrixFields', {
                "bubbles": true,
                "cancelable": true
            })
        );
        // initialise entries select fields for the entry
        form.dispatchEvent(
            new Event('oscar.updateEntriesSelectFields', {
                "bubbles": true,
                "cancelable": true
            })
        );
        // add file field event listeners
        addFileEventListeners(form);
        // add entries list field event listeners
        initEntriesListFields(form);
        // initialise auto-expanding text areas
        let textareas = form.querySelectorAll('textarea[data-auto-expand]');
        if (textareas) {
            Array.prototype.forEach.call(textareas, function(item) {
                autoExpand(item);
            });
        }
    };

    document.addEventListener('oscar.initFormFields', function(ev) {
        let form = ev.target.closest('form');
        initFormFields(form);
    });

    /******************************************
     * Bouncer to validate forms
     *
     ******************************************/
    let validate = new Bouncer('[data-validate]', {
        messageAfterField: false,
        errorClass: 'form-error',
        fieldClass: 'hasError',
        disableSubmit: true,
        emitEvents: true,
        messages: {
            missingValue: {
                checkbox: 'This field is required.',
                radio: 'Please select a value.',
                select: 'Please select a value.',
                'select-one': 'Please select a value.',
                'select-multiple': 'Please select at least one value.',
                default: 'Please fill out this field.'
            },
            patternMismatch: {
                email: 'Please enter a valid email address.',
                url: 'Please enter a URL.',
                number: 'Please enter a number',
                color: 'Please match the following format: #rrggbb',
                date: 'Please use the YYYY-MM-DD format',
                time: 'Please use the 24-hour time format. Ex. 23:00',
                month: 'Please use the YYYY-MM format',
                default: 'Please match the requested format.'
            },
            outOfRange: {
                over: 'Please select a value that is no more than {max}.',
                under: 'Please select a value that is no less than {min}.'
            },
            wrongLength: {
                over: 'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters.',
                under: 'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters.'
            },
            fallback: 'There was an error with this field.'
        },
    });

    /******************************************
     * Debounce submit button and submit form
     * on successful validation by Bouncer
     *
     ******************************************/
    const forms = document.querySelectorAll('[data-validate]');

    if (forms.length) {
        document.addEventListener('bouncerFormValid', function(ev) {
            const form = ev.target;

            if (form.dataset.confirm) {
                if (!confirm(form.dataset.confirm)) {
                    return false;
                }
            }

            let submitButton = form.querySelector('button[data-submit]');
            let submittingButton = form.querySelector('button[data-submitting]');

            if (submitButton) {
                submitButton.classList.add('hidden');
            }

            if (submittingButton) {
                submittingButton.classList.remove('hidden');
            }

            form.submit();
        });
    }
})();